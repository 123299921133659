import { useState } from 'react'

import { Button, Form, Tabs } from 'antd'

import {
  // StatusAlertForm,
  StockAlertForm,
} from '../Form'

import { BaseModalProps } from '@/common'
import { BaseModal } from '@/components'

interface AlertConfigurationModalProps extends BaseModalProps {}

type AlertTabs = 'status' | 'stock'

export const AlertConfigurationModal = ({ isOpen, onClose }: AlertConfigurationModalProps) => {
  const [activeTab, setActiveTab] = useState<AlertTabs>('stock')
  const [isFormTouched, setIsFormTouched] = useState(false)
  const [form] = Form.useForm()

  const tabItems = [
    // {
    //   key: 'status',
    //   label: 'Alertas',
    //   children:
    //     activeTab === 'status' ? <StatusAlertForm form={form} onSubmit={handleSubmit} /> : null,
    // },
    {
      key: 'stock',
      label: 'Estoque',
      children:
        activeTab === 'stock' ? (
          <StockAlertForm form={form} onSubmit={handleSubmit} setIsFormTouched={setIsFormTouched} />
        ) : null,
    },
  ]

  function handleSubmit(values: any) {
    console.log({ values })
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Configuração de alertas"
      width={720}
      classNames={{
        body: 'alert-modal-body',
      }}
      footer={
        <>
          <Button type="link" htmlType="button" onClick={onClose}>
            Cancelar
          </Button>

          <Button
            type="primary"
            htmlType="button"
            disabled={!isFormTouched}
            onClick={() => form.submit()}
          >
            Salvar
          </Button>
        </>
      }
    >
      <Tabs
        defaultActiveKey="status"
        tabPosition={'left'}
        style={{ height: 300 }}
        items={tabItems}
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key as AlertTabs)}
      />
    </BaseModal>
  )
}
