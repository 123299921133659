import { Divider, Form, Image } from 'antd'

import * as S from './styles'

import { getUploadedImageUrl, SelectWithLabel, truncateString } from '@/common'
import { BgFileUploadData, formatProductType, Product } from '@/features/BG'
import { CreateKitFormValues } from '@/features/kits/types'

type KitsSummaryProps = {
  kitImagesData?: BgFileUploadData[]
  formValues?: CreateKitFormValues
}

export const KitMountingSummary = ({ kitImagesData, formValues }: KitsSummaryProps) => {
  const form = Form.useFormInstance()

  const categoryObject: SelectWithLabel<string> = form.getFieldValue('category_id')

  const ProductCard: React.FC<{ product: Product }> = ({ product }) => {
    return (
      <S.ItemContainer>
        <S.ImageContainer>
          <Image
            height={64}
            width={74}
            preview={false}
            src={product?.sku?.sku_files?.[0]?.filename_url}
          />
        </S.ImageContainer>

        <S.ProductType>{formatProductType(product?.product_type_name || '--')}</S.ProductType>
        <S.ProductCategory>{truncateString(product?.category_name || '--', 32)}</S.ProductCategory>
        <S.PartNumber>{truncateString(product?.ref_id || '--', 28)}</S.PartNumber>
        <S.BGName>{truncateString(product?.model || '--', 72)}</S.BGName>
        {product?.sku?.stock_quantity_vtex && (
          <S.Stock>
            Estoque VTEX:
            <S.StockValue $isLowStock={product?.sku?.low_stock_vtex}>
              {product?.sku?.stock_quantity_vtex}
            </S.StockValue>
          </S.Stock>
        )}
        {product?.sku?.stock_quantity_anymarket && (
          <S.Stock>
            Estoque Anymarket:
            <S.StockValue $isLowStock={product?.sku?.low_stock_anymarket}>
              {product?.sku?.stock_quantity_anymarket}
            </S.StockValue>
          </S.Stock>
        )}
      </S.ItemContainer>
    )
  }

  return (
    <S.SummaryContainer>
      <S.Container>
        <S.CardLabel>Informações Gerais</S.CardLabel>

        <S.Card>
          <S.InfoContainer>
            <S.Row>
              <S.Column>
                <S.FieldLabel>Categoria</S.FieldLabel>

                <S.FieldValue>
                  {formValues?.category_id.label || categoryObject.label || '--'}
                </S.FieldValue>
              </S.Column>

              <Divider type="vertical" style={{ height: '32px' }} />

              <S.Column>
                <S.FieldLabel>Part Number</S.FieldLabel>

                <S.FieldValue>
                  {formValues?.ref_id || form.getFieldValue('ref_id') || '--'}
                </S.FieldValue>
              </S.Column>

              <Divider type="vertical" style={{ height: '32px' }} />

              <S.Column>
                <S.FieldLabel>Nome</S.FieldLabel>

                <S.FieldValue>
                  {formValues?.name || form.getFieldValue('name') || '--'}
                </S.FieldValue>
              </S.Column>
            </S.Row>

            <S.Row>
              <S.Column>
                <S.FieldLabel>Palavras Similares</S.FieldLabel>

                <S.KeyWordsValue>
                  {(formValues?.key_words || form.getFieldValue('key_words'))?.join(', ') || '--'}
                </S.KeyWordsValue>
              </S.Column>

              <Divider type="vertical" style={{ height: '32px' }} />

              <S.Column>
                <S.FieldLabel>Link</S.FieldLabel>

                <S.FieldValue>
                  {formValues?.link_id || form.getFieldValue('link_id') || '--'}
                </S.FieldValue>
              </S.Column>

              <Divider type="vertical" style={{ height: '32px' }} />

              <S.Column>
                <S.FieldLabel>Descrição (Title Tag)</S.FieldLabel>

                <S.FieldValue>
                  {formValues?.meta_tag_description ||
                    form.getFieldValue('meta_tag_description') ||
                    '--'}
                </S.FieldValue>
              </S.Column>
            </S.Row>

            <S.Row>
              <S.Column>
                <S.FieldLabel>Dealer Page (URL)</S.FieldLabel>

                <S.FieldLink
                  href={formValues?.dealer_page_url || form.getFieldValue('dealer_page_url')}
                  target="_blank"
                  rel="noopener noreferrer external"
                >
                  {formValues?.dealer_page_url || form.getFieldValue('dealer_page_url') || '--'}
                </S.FieldLink>
              </S.Column>
            </S.Row>

            <S.Row>
              <S.Column>
                <S.FieldLabel>Peso para frete</S.FieldLabel>

                <S.FieldValue>
                  {formValues?.packaged_weight_kg ||
                    form.getFieldValue('packaged_weight_kg') ||
                    '--'}
                </S.FieldValue>
              </S.Column>

              <Divider type="vertical" style={{ height: '32px' }} />

              <S.Column>
                <S.FieldLabel>Altura para frete</S.FieldLabel>

                <S.FieldValue>
                  {formValues?.packaged_height || form.getFieldValue('packaged_height') || '--'}
                </S.FieldValue>
              </S.Column>

              <Divider type="vertical" style={{ height: '32px' }} />

              <S.Column>
                <S.FieldLabel>Largura para frete</S.FieldLabel>

                <S.FieldValue>
                  {formValues?.packaged_width || form.getFieldValue('packaged_width') || '--'}
                </S.FieldValue>
              </S.Column>

              <Divider type="vertical" style={{ height: '32px' }} />

              <S.Column>
                <S.FieldLabel>Comprimento</S.FieldLabel>

                <S.FieldValue>
                  {formValues?.packaged_length || form.getFieldValue('packaged_length') || '--'}
                </S.FieldValue>
              </S.Column>
            </S.Row>

            <S.Row>
              <S.DescriptionColumn>
                <S.FieldLabel>Descrição</S.FieldLabel>

                <S.DescriptionContainer>
                  <pre>{formValues?.description || form.getFieldValue('description')}</pre>
                </S.DescriptionContainer>
              </S.DescriptionColumn>
            </S.Row>
          </S.InfoContainer>
        </S.Card>
      </S.Container>

      <S.Container>
        <S.CardLabel>Itens</S.CardLabel>

        <S.Card>
          <S.ItensContainer>
            {formValues?.selectedProducts
              ? formValues?.selectedProducts.map((data: Product, i: number) => (
                  <ProductCard key={i} product={data} />
                ))
              : []}
          </S.ItensContainer>
        </S.Card>
      </S.Container>

      <S.Container>
        <S.CardLabel>Imagens</S.CardLabel>

        <S.Card>
          <S.ImagesContainer>
            {kitImagesData?.map((data, i) => (
              <Image key={i} src={getUploadedImageUrl(data)} width={124} height={124} />
            ))}
          </S.ImagesContainer>
        </S.Card>
      </S.Container>
    </S.SummaryContainer>
  )
}
