import { Card as AntdCard } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

type StockProps = {
  $isLowStock?: boolean
}

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: calc(100% / 8);
  height: ${rem(176)};
  gap: 6px;
  border-radius: 8px;
  border-right: 1px solid #d9d9d9;

  &:nth-child(8n) {
    border-right: none;
  }
`

export const Container = styled.div``

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  img {
    margin: 4px;
    object-fit: cover !important;
    max-height: 64px;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const DescriptionColumn = styled(Column)`
  width: 100%;
`

export const Card = styled(AntdCard)`
  padding: 0px;
`

export const CardContent = styled.div`
  min-height: 240px;
  padding: 24px;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 240px;
  gap: 16px;
  padding: 24px;
`

export const ItensContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 224px;
  padding: 24px;
  border-radius: 8px;
  gap: 24px;
  overflow-y: auto;
`

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 174px;
  padding: 24px;
  border-radius: 8px;
  gap: 14px;
  overflow-y: auto;
  img {
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    object-fit: contain;
  }
`

export const CardLabel = styled.h3`
  font-size: ${rem(14)};
  font-weight: 700;
  color: #303030cc;
  padding-bottom: 8px;
`

export const FieldLabel = styled.span`
  font-size: ${rem(12)};
  color: #6c6c6c;
`

export const FieldValue = styled.span`
  display: flex;
  font-size: ${rem(14)};
  font-weight: 700;
  color: #303030cc;
  padding-bottom: 8px;
`

export const FieldLink = styled.a`
  display: flex;
  font-size: ${rem(14)};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.brand};
  padding-bottom: 8px;
`

export const SelectedText = styled.span`
  color: rgba(128, 195, 67, 1);
  font-size: ${rem(14)};
  font-weight: 600;
  margin-top: 0;
`

export const SelectedContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
`

export const ProductType = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  color: #8e8e8e;
  font-size: ${rem(12)};
`

export const ProductCategory = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  color: #434343;
  font-weight: 700;
  font-size: ${rem(14)};
  padding-right: 14px;
  padding-left: 14px;
  line-height: 14px;
`

export const PartNumber = styled.div`
  font-size: ${rem(12)};
  margin-top: -4px;
  text-align: center;
  color: #8e8e8e;
`

export const BGName = styled.strong`
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: ${rem(12)};
  font-weight: 700;
  line-height: 12px;
  padding-right: 10px;
  padding-left: 10px;
  color: #434343;
`

export const DescriptionContainer = styled.div`
  height: 100%;
  width: 100%;
  max-height: 100px;
  overflow-y: auto;

  pre {
    font-size: ${rem(12)};
    font-weight: 400;
    max-width: 600px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`

export const KeyWordsValue = styled(FieldValue)`
  max-width: 600px;
  white-space: pre-wrap;
  word-wrap: break-word;
`

export const Stock = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
  color: #6c6c6c;
  line-height: 8px;
  font-size: ${rem(10)};
`

export const StockValue = styled.div<StockProps>`
  display: flex;
  justify-content: center;
  color: ${(props) => (props.$isLowStock ? '#FF4D4D' : '#4A91FD')};
  font-weight: 700;
  font-size: ${rem(10)};
`
