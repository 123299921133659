import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  max-height: 300px;
  overflow-y: auto;
  padding-right: 24px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const RuleDescription = styled.span`
  font-size: ${rem(12)};
  color: #6c6c6c;
`

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .ant-input-number {
    max-width: 60px;
  }

  span {
    margin: 0;
  }
`
