import { Button as AntdButton, Card as AntdCard, Divider as AntdDivider } from 'antd'
import { rem } from 'polished'
import styled, { css } from 'styled-components'

interface ProductPriceProps {
  $hasMarketplacePrice: boolean
}

type StockProps = {
  $isLowStock?: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .ant-typography {
    line-height: initial;
  }

  /* .ant-card .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  } */

  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% / 8);
  height: ${rem(194)};
  gap: 8px;
  border-radius: 8px;
  border-right: 1px solid #d9d9d9;

  &:nth-child(8n) {
    border-right: none;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(128, 195, 67, 0.1);
    transition: all 0.3s;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  img {
    object-fit: cover !important;
    max-height: 64px;
  }
`

export const FieldLink = styled.a`
  display: flex;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.brand};
  padding-bottom: 8px;
`

export const ProductType = styled.div`
  display: flex;
  justify-content: center;
  padding: 2px;
  line-height: 14px;
  font-size: ${rem(12)};
  text-align: center;
  color: #8e8e8e;
`

export const ProductCategory = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  color: #434343;
  font-weight: 700;
  font-size: ${rem(14)};
  line-height: 14px;
  margin-top: -4px;
`

export const PartNumber = styled.div`
  font-size: ${rem(12)};
  margin-top: -4px;
  text-align: center;
  color: #8e8e8e;
  line-height: 12px;
`

export const BGName = styled.strong`
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: ${rem(12)};
  font-weight: 700;
  line-height: 8px;
  padding-right: 10px;
  padding-left: 10px;
  color: #434343;
`

export const Row = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const PageButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const BackButton = styled(AntdButton)`
  padding: 0;
`

export const ProductPrice = styled.div<ProductPriceProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  font-weight: 700;
  font-size: ${rem(12)};
  color: ${({ theme }) => theme.colors.black};
  ${({ $hasMarketplacePrice }) =>
    !$hasMarketplacePrice &&
    css`
      color: ${({ theme }) => theme.colors.lightGray};

      img {
        opacity: 0.2;
      }
    `}
`

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  .ant-card .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
  }
`

export const Card = styled(AntdCard)`
  padding: 0;
`

export const KitInfoCard = styled(AntdCard)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FieldLabel = styled.span`
  font-size: 12px;
  color: #6c6c6c;
`

export const FieldValue = styled.span`
  display: flex;
  font-size: 14px;
  font-weight: 700;
  color: #303030cc;
  padding-bottom: 8px;
`

export const FreightInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 10px;
  border-radius: 8px;
  background-color: #f8f8f8;
  width: calc(100% / 4.5);
`

export const FreightValues = styled.div`
  display: flex;
  gap: 8px;
`

export const Divider = styled(AntdDivider)`
  align-items: center;
  height: 32px;
`

export const CardLabel = styled.h3`
  font-size: 14px;
  font-weight: 700;
  color: #303030cc;
  padding-bottom: 8px;
`

export const ItensContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 198px;
  border-radius: 8px;
  gap: 4px;
  overflow-y: auto;
`

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;
  gap: 16px;
  overflow-y: auto;
  img {
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    object-fit: contain;
  }
`

export const DescriptionContainer = styled.div`
  height: 100%;
  width: 100%;
  max-height: 100px;
  overflow-y: auto;

  pre {
    font-size: 12px;
    font-weight: 400;
    max-width: 600px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`

export const Stock = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
  color: #6c6c6c;
  line-height: 6px;
  font-size: ${rem(10)};
`

export const StockValue = styled.div<StockProps>`
  display: flex;
  justify-content: center;
  color: ${(props) => (props.$isLowStock ? '#FF4D4D' : '#4A91FD')};
  font-weight: 700;
  font-size: ${rem(10)};
`
