import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons'
import { Drawer as AntdDrawer, Button, Input, Select as AntdSelect, Skeleton, Table } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const Drawer = styled(AntdDrawer)`
  .ant-drawer-header {
    padding: 0px 20px;
  }

  .ant-drawer-body {
    padding: 24px;
  }

  .ant-drawer-title {
    font-size: 15px;
    font-weight: 800;
  }

  .ant-drawer-extra {
    color: ${({ theme }) => theme.colors.brand};

    :hover {
      color: rgba(94, 163, 32, 0.7);
    }
  }

  .ant-drawer-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  .ant-tabs-tab {
    font-size: ${rem(16)};
    line-height: ${rem(18)};
  }
`

export const CloseButton = styled(Button)`
  color: ${({ theme }) => theme.colors.brand};
  border: 1px solid ${({ theme }) => theme.colors.brand};

  :hover {
    color: rgba(94, 163, 32, 0.6);
  }
`

export const FirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-input-search-button {
    border-left: none;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    border-color: ${({ theme }) => theme.colors.brand};
  }
  .ant-btn-default:disabled,
  .ant-input.ant-input-disabled {
    border-color: ${({ theme }) => theme.colors.colorBorderPrimary} !important;
  }
  .ant-input-group > .ant-input:first-child {
    border-color: ${({ theme }) => theme.colors.brand};
    box-shadow: none;
    border-radius: 4px;
  }
`

export const LastUpdateDate = styled.div`
  color: #434343;
  strong {
    color: #4a91fd;
  }
  padding: 12px 0 24px 0;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 16px;
`

export const Content = styled.div`
  display: flex;
  align-items: center;

  line-height: 32px;

  .copy-button {
    color: transparent;
    background-color: transparent;
  }

  &:hover .copy-button {
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const TextKey = styled.span`
  color: #00000099;
  flex: 1;

  line-height: 13px;
`

export const TextDescription = styled.span`
  color: #434343;
  flex: 1;

  font-weight: 600;
  padding-left: 4px;
  line-height: 18px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 9px;
  width: 100%;
  .ant-form-item {
    width: 48%;
  }
`

export const SearchInput = styled(Input.Search)`
  height: 30px;
  color: #434343 !important;
  transition: all 0.6s ease-in-out;
  .ant-input-group-addon {
    display: none !important;
  }
`

export const CloseIcon = styled(CloseOutlined)`
  transition: all 0.2s ease;
  cursor: pointer;
`

export const ClearFilter = styled(Button)`
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
`

export const SecondContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  .select,
  .input {
    width: 100%;
    margin-bottom: 0;
  }

  .ant-form-item-label > label {
    color: #6c6c6c;
  }

  .ant-form-item .ant-form-item-control-input-content {
    height: 34px;
  }
`

export const DescriptionText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ul {
    li {
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin: 8px 0;
    }
  }
`

export const ExampleText = styled.strong`
  color: #5f5f5f;
`

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const UploadContainer = styled.div`
  .ant-upload-wrapper {
    background-color: #f8f8f8;
    border-radius: 8px;
  }
  .ant-upload-drag {
    height: 106px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
  .anticon.anticon-upload {
    color: #80c343;
    font-size: 26px;
    padding-bottom: 6px;
  }
  .anticon.anticon-delete {
    color: ${({ theme }) => theme.colors.error} !important;
  }
`

export const Select = styled(AntdSelect)`
  width: 100%;
`

export const SearchContent = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 12px;
`

export const SearchValueTitle = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 16px 0 14px 0;

  strong {
    color: ${({ theme }) => theme.customTokens.text};
  }
`

export const TotalPages = styled.strong`
  color: ${({ theme }) => theme.colors.matteBlue};
`

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 10%;
`

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const SkeletonContent = styled.div`
  display: flex;
  gap: 12px;
`

export const SkeletonInput = styled(Skeleton.Input)`
  width: 334px !important;
`

export const SkeletonUpload = styled(Skeleton.Input)`
  width: 100% !important;
  height: 104px !important;
  border-radius: 4px;
`

export const SkeletonSearchInput = styled(Skeleton.Input)`
  width: 100% !important;
  border-radius: 4px;
`

export const ListValueTitle = styled(SearchValueTitle)`
  margin: 12px 0;
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`

export const PaginationContainer = styled.div`
  margin-top: auto;
`
export const StyledTable = styled(Table)`
  .ant-table-cell-row-hover {
    background: ${({ theme }) => theme.colors.brand} !important;
    cursor: pointer;
  }
`

export const BomDetailsHeader = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
`
