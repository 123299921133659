import { useState } from 'react'

import { useQueries } from '@tanstack/react-query'
import { Form, Input, Select } from 'antd'

import * as S from './styles'

import { useDebounce, filtersRemap } from '@/common'
import { HelpTooltip } from '@/components'
import { categoryQueries } from '@/features/categories/queries'
import { productTypeQueries } from '@/features/product-types'

interface CategoryFormProps {
  isEditingCategory?: boolean
}

export const CategoryForm = ({ isEditingCategory }: CategoryFormProps) => {
  const [globalCategoryName, setGlobalCategoryName] = useState('')
  const debouncedGlobalCategory = useDebounce(globalCategoryName, 500)

  const [
    { data: productTypes, isLoading: isProductTypesLoading },
    {
      data: globalCategories,
      isLoading: isGlobalCategoriesLoading,
      isFetching: isGlobalCategoriesFetching,
    },
    { data: anymarketCategories, isLoading: isAnymarketCategoriesLoading },
  ] = useQueries({
    queries: [
      { ...productTypeQueries.list({ page: 1, per_page: 100 }) },
      { ...categoryQueries.globalCategories(debouncedGlobalCategory) },
      { ...categoryQueries.anymarketCategories() },
    ],
  })

  const productTypeOptions = productTypes?.product_types?.map((value) => ({
    value: value.vtex_id,
    label: (filtersRemap.get(value.name) as string) || value.name,
  }))

  const globalCategoriesOptions = globalCategories?.google_global_categories.map((value) => ({
    value: value.google_id,
    label: value.name,
  }))

  const anymarketCategoriesOptions = anymarketCategories?.map((category) => ({
    value: category.id,
    label: category.name,
  }))

  return (
    <S.FormContainer>
      <Form.Item
        name={'name'}
        label={'Nome'}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input placeholder="ex: Jogos..." />
      </Form.Item>

      <S.FormRow>
        <Form.Item
          name={'title'}
          label={'Título da Página'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input placeholder="Jogos de Pc" />
        </Form.Item>

        <Form.Item
          name={'keywords'}
          label={
            <S.LabelContainer>
              <span>Palavras Similares</span>

              <HelpTooltip title="As palavras similares devem ser separadas usando vírgula." />
            </S.LabelContainer>
          }
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          getValueProps={(keywordString) => {
            if (!keywordString) return { value: [] }
            return { value: keywordString.split(',').map((keyword: string) => keyword.trim()) }
          }}
          getValueFromEvent={(event) => event.join(', ')}
        >
          <Select
            mode="tags"
            tokenSeparators={[',']}
            size="large"
            placeholder="Ex: Acessórios"
            loading={isGlobalCategoriesLoading || isGlobalCategoriesFetching}
            notFoundContent="Adicione uma palavra"
            style={{ maxWidth: '100%' }}
          />
        </Form.Item>
      </S.FormRow>

      <S.FormRow>
        <Form.Item
          name={'google_global_category_id'}
          label={'Categoria Global'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            showSearch
            labelInValue
            size="large"
            placeholder="Selecione a categoria global"
            options={globalCategoriesOptions}
            loading={isGlobalCategoriesLoading || isGlobalCategoriesFetching}
            onSearch={setGlobalCategoryName}
            filterOption={false}
          />
        </Form.Item>

        <Form.Item
          name={'product_type_vtex_id'}
          label={'Tipo de Produto'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            size="large"
            placeholder="Selecione o tipo de produto"
            options={productTypeOptions || []}
            loading={isProductTypesLoading}
            disabled={isEditingCategory}
          />
        </Form.Item>

        <Form.Item
          name={'anymarket_id'}
          label={'Categoria AnyMarket'}
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            size="large"
            placeholder="Selecione a categoria anymarket"
            options={anymarketCategoriesOptions || []}
            loading={isAnymarketCategoriesLoading}
          />
        </Form.Item>
      </S.FormRow>

      <Form.Item
        name={'description'}
        label={'Descrição'}
        rules={[{ required: true, message: 'Campo obrigatório' }]}
      >
        <Input.TextArea placeholder="Descrição da categoria..." style={{ height: 80 }} />
      </Form.Item>
    </S.FormContainer>
  )
}
