import { ExclamationCircleFilled, FileDoneOutlined } from '@ant-design/icons'
import { Button, Modal, Switch } from 'antd'
import { ColumnsType } from 'antd/es/table'

import * as S from './styles'

import { filtersRemap } from '@/common'
import { BaseTable, TableAction, TableColumnButton } from '@/components'
import type { Category } from '@/features/categories'

type CategoriesListTableProps = {
  data: Category[] | undefined
  isLoading?: boolean
  handleEditCategories: (category: Category) => void
  handleChangeCategoryStatus: (category: Category, status: boolean) => void
}

export const CategoriesListTable = ({
  data,
  isLoading,
  handleEditCategories,
  handleChangeCategoryStatus,
}: CategoriesListTableProps) => {
  const columns: ColumnsType<Category> = [
    {
      title: 'ID Vtex',
      dataIndex: 'IdVtex',
      key: 'IdVtex',
      align: 'center',
      width: 50,
      render: (_, record) => <p>{record.vtex_id}</p>,
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 122,
      render: (_, record) => (
        <TableColumnButton onClick={() => handleEditCategories(record)}>
          {record.name}
        </TableColumnButton>
      ),
    },
    {
      title: 'Título da Página',
      dataIndex: 'PageTitle',
      key: 'PageTitle',
      width: 194,
      render: (_, record) => <p>{record.title}</p>,
    },
    {
      title: 'Palavras similares',
      dataIndex: 'keywords',
      key: 'SimilarWords',
      width: 190,
      render: (_, { keywords }) => <p>{keywords}</p>,
    },
    {
      title: 'Descrição',
      dataIndex: 'Description',
      key: 'Description',
      width: 260,
      render: (_, record) => <p>{record.description || '--'}</p>,
    },
    {
      title: 'Tipo de Produto',
      dataIndex: 'ParentCategory',
      key: 'ParentCategory',
      align: 'center',
      width: 130,
      render: (_, record) => (
        <p>{(filtersRemap.get(record.product_type.name) as string) || record.product_type.name}</p>
      ),
    },
    {
      title: 'Categoria Global',
      dataIndex: 'global_category',
      key: 'global_category',
      align: 'center',
      width: 130,
      render: (_, record) => {
        return <p>{record.global_category_name || record.global_category_id}</p>
      },
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      align: 'center',
      width: 88,
      render: (isActive, category) => (
        <Switch
          checked={isActive}
          onClick={() => confirmChangeStatus(category, isActive)}
          checkedChildren={'Ativo'}
          unCheckedChildren={'Inativo'}
        />
      ),
    },
    {
      title: 'Ações',
      dataIndex: '',
      key: 'actions',
      align: 'center',
      width: 55,
      render: (_, category) => {
        const dropdownOptions = [
          {
            key: '1',
            label: (
              <S.ButtonContent>
                <Button
                  icon={<FileDoneOutlined />}
                  type="link"
                  onClick={() => handleEditCategories(category)}
                >
                  Dados cadastrais
                </Button>
              </S.ButtonContent>
            ),
          },
        ]

        return <TableAction dropdownOptions={dropdownOptions} />
      },
    },
  ]

  function confirmChangeStatus(category: Category, isActive: boolean) {
    Modal.confirm({
      title: `Deseja ${isActive === true ? 'Desativar' : 'Ativar'} Categoria?`,
      centered: true,
      icon: <ExclamationCircleFilled style={{ color: '#FAAD14' }} />,
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => handleChangeCategoryStatus(category, !category.is_active),
      footer: (_, { OkBtn, CancelBtn }) => (
        <S.ModalFooter>
          <CancelBtn />
          <OkBtn />
        </S.ModalFooter>
      ),
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          background: '#80c343',
        },
      },
      cancelButtonProps: {
        danger: true,
      },
    })
  }

  return (
    <BaseTable
      dataSource={data}
      columns={columns}
      rowKey={(record) => record.id}
      pagination={false}
      loading={isLoading}
    />
  )
}
