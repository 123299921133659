import { Button, Input } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

export const InformationRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0 24px 0;

  > div {
    gap: 4px;

    color: #434343;
    font-size: ${rem(14)};

    strong {
      color: #4a91fd;
    }
  }
`

export const Instruction = styled.span`
  color: #434343;
  font-size: ${rem(14)};
  display: block;
`

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .ant-form-item {
    width: 48%;
    margin-inline-end: 0;
  }
`

export const FilterArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const SearchInput = styled(Input.Search)`
  height: 30px;
  color: #434343 !important;

  /* .ant-input-group-addon {
    display: none !important;
  } */
`

export const ClearFilterButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  padding: 0 !important;
  height: fit-content;
`

export const BeginTourButton = styled(Button)`
  padding: 0 !important;
  height: fit-content;
`

export const TableContainer = styled.div`
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-table-cell-row-hover {
    /* background: ${({ theme }) => theme.colors.brand} !important; */
    cursor: pointer;
  }
`

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 10%;
`

export const TourContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
