import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { Image } from 'antd'

import * as S from './styles'

import { imgFallback, truncateString } from '@/common'
import { formatProductType, Product } from '@/features/BG'

type ItemCardProps = {
  data: Product
  isInSelection?: boolean
  isSelected?: boolean
  onSelectProduct?: (product: Product, isSelected: boolean) => void
}

export const ItemCard = ({ data, isInSelection, isSelected, onSelectProduct }: ItemCardProps) => {
  return (
    <S.Container
      $isInSelection={isInSelection}
      $isSelected={isSelected}
      onClick={() => onSelectProduct?.(data, !isSelected)}
    >
      {!isInSelection && (
        <S.DeleteButton
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => onSelectProduct?.(data, false)}
        />
      )}

      <S.ImageContainer>
        <Image
          height={64}
          width={74}
          preview={false}
          src={data?.sku?.sku_files?.[0]?.filename_url}
          fallback={imgFallback}
        />
      </S.ImageContainer>

      {isSelected ? (
        <S.SelectedContent>
          <CheckCircleOutlined style={{ fontSize: '32px', color: 'rgba(128, 195, 67, 1)' }} />
          <S.SelectedText>Adicionado</S.SelectedText>
        </S.SelectedContent>
      ) : null}

      <S.ProductType>{formatProductType(data?.product_type_name || '--')}</S.ProductType>

      <S.ProductCategory>{truncateString(data?.category_name || '--', 32)}</S.ProductCategory>

      <S.PartNumber>{truncateString(data?.ref_id || '--', 28)}</S.PartNumber>

      <S.BGModel>{truncateString(data?.model || '--', 72)}</S.BGModel>

      {data?.sku?.stock_quantity_vtex && (
        <S.Stock>
          Estoque VTEX:
          <S.StockValue $isLowStock={data?.sku?.low_stock_vtex}>
            {data?.sku?.stock_quantity_vtex}
          </S.StockValue>
        </S.Stock>
      )}
      {data?.sku?.stock_quantity_anymarket && (
        <S.Stock>
          Estoque Anymarket:
          <S.StockValue $isLowStock={data?.sku?.low_stock_anymarket}>
            {data?.sku?.stock_quantity_anymarket}
          </S.StockValue>
        </S.Stock>
      )}
    </S.Container>
  )
}
