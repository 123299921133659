import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Form, FormInstance, InputNumber, Radio, Skeleton } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import {
  alertQueries,
  useCreateStockAlertMutation,
  useUpdateStockAlertMutation,
} from '@/features/alerts'

interface StockAlertFormProps {
  form: FormInstance<any>
  onSubmit: (values: any) => void
  setIsFormTouched: React.Dispatch<React.SetStateAction<boolean>>
}

export const StockAlertForm = ({ form, onSubmit, setIsFormTouched }: StockAlertFormProps) => {
  const { data: stockSettings, isLoading } = useQuery(alertQueries.stockSettings())

  const createStockAlertMutation = useCreateStockAlertMutation()
  const updateStockAlertMutation = useUpdateStockAlertMutation()

  useEffect(() => {
    if (stockSettings) {
      form.setFieldsValue({
        amount: stockSettings.stock.minimal_stock,
        alert_type: stockSettings.stock.type_of_alert,
      })
    }
  }, [stockSettings, form])

  function handleValuesChange(_: any, allValues: any) {
    const hasValue = !!allValues.amount && !!allValues.alert_type
    setIsFormTouched(hasValue)
  }

  const handleSubmit = async () => {
    try {
      const isUpdating = !!stockSettings
      const mutation = isUpdating ? updateStockAlertMutation : createStockAlertMutation
      const configStatus = isUpdating ? 'atualizada' : 'criada'

      const payload = {
        minimal_stock: form.getFieldValue('amount'),
        type_of_alert: form.getFieldValue('alert_type'),
      }

      const promise = mutation.mutateAsync(payload)
      toast.promise(promise, {
        loading: 'Salvando configurações de Estoque...',
        error: 'Erro ao salvar configurações de Estoque',
        success: () => (
          <S.ToastContainer>
            <S.CircleOutlined />
            <div>
              Configuração <strong>{configStatus}</strong> com <strong>sucesso</strong>!
            </div>
          </S.ToastContainer>
        ),
      })

      onSubmit((await promise).data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      form={form}
      onFinish={handleSubmit}
      onValuesChange={handleValuesChange}
    >
      {isLoading ? (
        <S.Container>
          <Skeleton active />

          <Skeleton active />
        </S.Container>
      ) : (
        <S.Container>
          <S.TextContainer>
            <span>
              Defina a <strong>quantidade mínima</strong> dos produtos em estoque e o{' '}
              <strong>tipo de alerta</strong> que deseja receber
            </span>
          </S.TextContainer>

          <Form.Item
            name="amount"
            label={
              <span>
                1- Quantidade mínima de itens em estoque necessária para ativar as notificações
              </span>
            }
            rules={[
              {
                required: true,
                message: 'Defina uma quantidade mínima',
              },
            ]}
          >
            <InputNumber
              min={0}
              max={999}
              keyboard={false}
              type="number"
              size="middle"
              placeholder="Ex: 100"
            />
          </Form.Item>

          <Form.Item
            name="alert_type"
            label={
              <span>2- Como deseja receber as notificações ao atingir a quantidade mínima</span>
            }
            rules={[
              {
                required: true,
                message: 'Selecione uma opção',
              },
            ]}
          >
            <Radio.Group>
              <Radio value={'SYSTEM'}>Apenas através deste sistema</Radio>
              <Radio value={'EMAIL'}>Por e-mail</Radio>
              <Radio value={'BOTH'}>Sistema e e-mail</Radio>
            </Radio.Group>
          </Form.Item>
        </S.Container>
      )}
    </Form>
  )
}
