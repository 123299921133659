import { RxExclamationTriangle } from 'react-icons/rx'

import {
  CameraOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { Button as AntdButton, Divider, Skeleton, Steps as StepsAntd, Typography } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

interface StockValueProps {
  $isLowStock: boolean | undefined
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;

  .ant-typography {
    line-height: initial;
  }

  position: relative;
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const FooterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

export const BackButton = styled(AntdButton)`
  padding: 0;
  color: ${(props) =>
    props.type === 'primary' ? props.theme.colors.white : props.theme.colors.darkGreen};
`

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 14px;
`

export const Price = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-size: 32px;
    font-weight: 700;
    color: #434343;
  }
`

export const Stock = styled.div`
  display: flex;
  gap: 4px;
  color: #6c6c6c;
`

export const StockValue = styled.div<StockValueProps>`
  color: ${(props) => (props.$isLowStock ? '#FF4D4D' : '#4A91FD')};
  font-weight: 700;
`

export const PriceDivider = styled(Divider)`
  height: 36px;
  border: 1px thick;
  margin: 0;
`

export const Date = styled(Typography.Text)`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
`

export const Button = styled(AntdButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 4px 15px;

  color: ${(props) =>
    props.type === 'default' ? props.theme.colors.darkGreen : props.theme.colors.white};
  border: ${(props) =>
    props.type === 'default'
      ? `1px solid ${props.theme.colors.darkGreen}`
      : props.theme.colors.brand};
`

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`

export const Title = styled.div`
  display: flex;
  gap: 8px;
  font-size: ${rem(24)};
  color: ${({ theme }) => theme.customTokens.text.darkGray};

  .ant-divider {
    margin: 0;
    border-color: ${({ theme }) => theme.customTokens.listBox.bgScrollBar};
    height: ${rem(30)};
  }
`

export const Subtitle = styled.span`
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.customTokens.text.darkGray};
`

export const BGInfoContainer = styled.div`
  display: flex;
  /* align-items: stretch; */
  height: fit-content;
  gap: 16px;
  /* flex: 1; */
`

export const BgProductInfoContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  padding: 18px;
`

export const imageContainer = styled.div`
  display: flex;
  align-items: center;

  max-height: ${rem(274)};
  max-width: ${rem(274)};
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  background-color: #fff;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
`

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  width: 100%;
  padding-bottom: 8px;
`

export const StatusContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const SummaryContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  padding: ${rem(14)};
`

export const Summary = styled.ul`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  /* color: rgba(0, 0, 0, 0.6); */
  font-size: ${rem(14)};

  display: flex;
  flex-direction: column;
  gap: 4px;

  ul {
    li {
      list-style-type: disc;
      padding-left: 0 1rem;
      margin-left: 1rem;
    }
  }
  ol {
    li {
      list-style-type: decimal;
      padding-left: 0 1rem;
      margin-left: 1rem;
    }
  }
`

export const ProductCategory = styled.div`
  width: 100%;

  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.customTokens.text.darkGray};

  font-size: ${rem(16)};

  .ant-divider {
    margin: 0 4px;
  }
`

export const DisplayName = styled.h3`
  width: 100%;
  font-weight: 700;
  font-size: ${rem(20)};
  color: #434343;
`

export const ProductSpecsContainer = styled.div`
  padding-top: 16px;
  ul {
    li {
      list-style-type: disc;
      padding-left: 0 1rem;
      margin-left: 1rem;
    }
  }
  ol {
    li {
      list-style-type: decimal;
      padding-left: 0 1rem;
      margin-left: 1rem;
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
`

export const SkeletonTab = styled(Skeleton.Input)`
  width: 250px !important;

  padding-top: 28px;
`

export const Steps = styled(StepsAntd)``

export const ExclamationTriangle = styled(RxExclamationTriangle)`
  font-size: 18px;
  color: #faad14;
  /* margin-left: 8px; */
`

export const CompleteRegisterButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${rem(35)};
  width: ${rem(153)};

  font-size: 14px;
`

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 16px;

  position: absolute;
  left: -24px;
  right: -24px;
  top: -16px;

  background: #fffbe6;
  border: 1px solid #ffe58f;
`

export const AlertContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const AlertText = styled(Typography.Text)`
  font-size: 16px;
  color: #303030;
`

export const FallbackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #f6f6f6;

  height: ${rem(152)};
  width: ${rem(174)};
`

export const FallbackIcon = styled(CameraOutlined)`
  font-size: 34px;
  color: ${({ theme }) => theme.colors.darkGreen};
`

export const Card = styled.div`
  display: flex;
  flex: 1;

  padding: 8px;

  flex-direction: column;
  gap: 8px;
  border-radius: 6px;

  background-color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  strong {
    font-size: 16px;
    font-weight: 700;
  }
`
export const WrapingText = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: ${rem(234)};
  overflow-x: auto;
  ol,
  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: ${rem(234)};
    padding-left: 4px;
    padding-bottom: 4px;
    column-gap: ${rem(18)};
    row-gap: 6px;
    overflow-x: auto;
  }
`

export const ExternalCharacCard = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px;

  gap: 8px;
  border-radius: 6px;
  margin-bottom: 15px;

  background-color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  strong {
    font-size: 16px;
    font-weight: 700;
  }
`

export const ImageViewer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-top: 28px;
  gap: 24px;

  width: 100%;
  max-width: calc(100vw - 116px);
  overflow: auto;

  padding: 12px 2px;
`

export const ExclamationIcon = styled(ExclamationCircleOutlined)`
  font-size: 56px;
  color: #ff4d4d;
`

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 8px;
  padding-bottom: 24px;

  width: 100%;
  height: 100%;
  font-size: 16px;

  .ant-typography {
    text-align: center;
  }
`

export const WarningIcon = styled(WarningOutlined)`
  font-size: 18px;
  color: #faad14;
`

export const ConfirmAgencyHeader = styled.div`
  width: 100%;

  padding: 16px;
  margin: -16px 0 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #fffbe6;
  border: 1px solid #fde58f;
  border-radius: 2px;

  color: #303030;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  > button {
    max-width: 248px;
  }
`

export const PackImageTitle = styled.h1`
  font-weight: 600;
  font-size: ${rem(20)};
  line-height: 1.5rem;
  color: #434343;
  padding: 4px 0;
`

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CircleOutlined = styled(CheckCircleOutlined)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 99%;
  background-color: #80c343;
`
export const AnymarketPublishContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
