import { useRef, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Empty, Form, Select, Table, Tour, TourProps } from 'antd'

import * as S from './styles'

import { formatDate, useToggle } from '@/common'
import { CustomPagination } from '@/components'
import { bgQueries, BomProductResponse } from '@/features/BG'

const INITIAL_FILTER_STATE = {
  per_page: 100,
  page: 1,
  model: undefined,
  ref_id: undefined,
  marketing_name: undefined,
}

export type BomSpreadsheetFilters = typeof INITIAL_FILTER_STATE

interface IBomSpecifications {
  onSelectRow: (bomProduct: BomProductResponse) => void
}

export const BomSpecifications = ({ onSelectRow }: IBomSpecifications) => {
  const [form] = Form.useForm()
  const [bomFilters, setBomFiltersState] = useState<BomSpreadsheetFilters>(INITIAL_FILTER_STATE)

  const {
    data: bomData,
    isLoading: isBomLoading,
    isError: isBomError,
  } = useQuery({ ...bgQueries.bom(bomFilters) })

  const tourRef1 = useRef(null)
  const tourRef2 = useRef(null)
  const tourRef3 = useRef(null)

  const [isTourOpen, toggleTour] = useToggle(false)

  const tourSteps: TourProps['steps'] = [
    {
      title: 'Data de atualização',
      description:
        'Esta foi a última data de atualização da planilha. Para atualizar novamente, faça um novo upload.',
      target: () => tourRef1.current,
    },
    {
      title: 'Filtros',
      description: (
        <S.TourContainer>
          <span>
            Escolha o critério de busca mais adequado para sua necessidade. Você pode pesquisar por
            diferentes características do produto, como{' '}
            <strong>modelo, part number ou marketing name</strong>.
          </span>

          <span>
            No campo de busca, digite o que deseja encontrar baseado no tipo de busca selecionado.
            Por exemplo, se selecionou{' '}
            <strong>
              &quot;<i>modelo</i>&quot;
            </strong>
            , digite o modelo do produto que procura.
          </span>
        </S.TourContainer>
      ),
      target: () => tourRef2.current,
    },
    {
      title: 'Tabela BOM',
      description:
        'Clique em algum produto ou linha da tabela para abrir mais informações sobre o produto.',
      target: () => tourRef3.current,
    },
  ]

  function handleFilter({
    selectFilter,
    searchValue,
  }: {
    selectFilter: string
    searchValue: string
  }) {
    setBomFiltersState((oldState) => ({
      ...oldState,
      page: 1,
      [selectFilter]: searchValue,
    }))
  }

  function handleClearFilters() {
    form.resetFields(['selectFilter', 'searchValue'])
    setBomFiltersState(INITIAL_FILTER_STATE)
  }

  function handlePagination(page: number, pageSize: number) {
    setBomFiltersState((oldState) => ({
      ...oldState,
      per_page: pageSize,
      page,
    }))
  }

  if (bomData && !bomData.imported_at) {
    return null
  }

  if (isBomError) {
    return (
      <S.EmptyContainer>
        <Empty
          description={
            <span>
              Falha ao carregar os dados da planilha <strong>BOM</strong>, por favor faça um novo
              upload.
            </span>
          }
        />
      </S.EmptyContainer>
    )
  }

  return (
    <>
      {isTourOpen && <Tour open={isTourOpen} onClose={toggleTour} steps={tourSteps} />}

      {bomData && (
        <S.InformationRow>
          <div ref={tourRef1}>
            <span>Planilha atualizada em:</span>{' '}
            <strong>
              {formatDate({ date: bomData.imported_at, dateFormat: 'DD/MM/YY' }) || '--/--/--'}
            </strong>
          </div>

          <S.BeginTourButton type="link" onClick={toggleTour}>
            Precisa de ajuda?
          </S.BeginTourButton>
        </S.InformationRow>
      )}

      <S.FilterArea ref={tourRef2}>
        <S.Instruction>Buscar produto:</S.Instruction>

        <Form form={form} layout="inline" onFinish={handleFilter}>
          <S.FormRow>
            <Form.Item name="selectFilter">
              <Select
                allowClear
                placeholder={'Modelo, Part Number, Marketing Name'}
                options={[
                  { label: 'Model', value: 'model' },
                  { label: 'Part Number', value: 'ref_id' },
                  { label: 'Marketing Name', value: 'marketing_name' },
                ]}
                onChange={() => form.setFieldsValue({ searchValue: '' })}
                // disabled={isCheckingStatus}
              />
            </Form.Item>

            <Form.Item name="searchValue" shouldUpdate>
              <S.SearchInput
                placeholder="Ex: AV15-53P-56HM"
                enterButton
                // disabled={!selectFilterValue || isCheckingStatus}
                onSearch={form.submit}
              />
            </Form.Item>
          </S.FormRow>
        </Form>

        {form.getFieldValue('searchValue') && (
          <S.ClearFilterButton
            type="link"
            onClick={handleClearFilters}
            // disabled={isCheckingStatus}
          >
            Limpar filtro
          </S.ClearFilterButton>
        )}
      </S.FilterArea>

      <S.TableContainer ref={tourRef3}>
        <div>
          <Table
            dataSource={bomData?.products || []}
            columns={[
              {
                title: 'Modelo',
                dataIndex: 'model',
              },
              {
                title: 'Part number',
                dataIndex: 'ref_id',
              },
              {
                title: 'Marketing name',
                dataIndex: 'marketing_name',
              },
            ]}
            pagination={false}
            onRow={(record: BomProductResponse) => {
              return {
                onClick: () => onSelectRow(record),
              }
            }}
            loading={isBomLoading}
          />

          <CustomPagination
            scrollToTop
            page={bomFilters.page || 1}
            pageSize={bomFilters.per_page || 1}
            totalItems={bomData?.total || 0}
            totalPages={bomData?.total_pages || 1}
            isLoading={
              isBomLoading
              // || isCheckingStatus
            }
            changePageValue={(page, pageSize) => handlePagination(page, pageSize)}
          />
        </div>
      </S.TableContainer>
    </>
  )
}
