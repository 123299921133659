import { Fragment, useEffect, useState } from 'react'

import { CloseOutlined, CopyOutlined, UploadOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Divider, Empty, Form, message, Upload, UploadProps } from 'antd'
import axios from 'axios'
import { toast } from 'sonner'

import { BomSpecifications } from './Bom'
import * as S from './styles'

import { ApiError, truncateString, useDebounce, useToggle } from '@/common'
import { LoadingSpinner } from '@/components'
import { TitleTabs } from '@/components/Layout/PageLayout'
import { bomProductLabelsRemap, BomProductResponse, useS3Url } from '@/features/BG'
import {
  bgQueries,
  useImportBomSpreadsheet,
  type Fabricantes,
  type ScrapingSpecification,
} from '@/features/BG'
import { queryClient } from '@/services'

type SpecificationsScrapingDrawerProps = {
  isOpen: boolean
  onClose: () => void
}

type TabKeys = 'bom' | 'telas' | 'fabricante'

export const SpecificationsScrapingDrawer = ({
  isOpen,
  onClose,
}: SpecificationsScrapingDrawerProps) => {
  const [antdToast, antdToastContextHolder] = message.useMessage()
  const [tabActiveKey, setTabActiveKey] = useState<TabKeys>('bom')
  const fetchS3Url = useS3Url()

  const [form] = Form.useForm()
  const selectedFabricante = Form.useWatch<Fabricantes>('fabricante', form)
  const scrapingSearchValue = Form.useWatch('search', form)
  const debouncedScrapingSearch = useDebounce(scrapingSearchValue?.trim(), 400)

  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [bomSpreadsheetUploadFile, setBomSpreadsheetUploadFile] = useState(() => {
    const currentUploadFile = sessionStorage.getItem('bom-upload')

    return currentUploadFile || ''
  })
  const [isCheckingBomImportStatus, setIsCheckingBomImportStatus] = useState(() => {
    const currentUploadFile = sessionStorage.getItem('bom-upload')

    return !!currentUploadFile || false
  })

  const [bomProductDetails, setBomProductDetails] = useState<undefined | BomProductResponse>(
    undefined,
  )
  const [isBomDetailsDrawerOpen, toggleBomDetailsDrawer] = useToggle(false)

  const importBgBomSpecMutation = useImportBomSpreadsheet()

  const {
    data: scraping,
    isLoading: isScrapingInitialLoading,
    isError,
    isFetched,
    fetchStatus,
  } = useQuery({
    ...bgQueries.scraping(selectedFabricante, debouncedScrapingSearch),
    enabled: !!debouncedScrapingSearch,
  })

  const { data: bomImportStatus } = useQuery({
    ...bgQueries.bomImportStatus(bomSpreadsheetUploadFile),
    enabled: !!bomSpreadsheetUploadFile,
    refetchInterval: isCheckingBomImportStatus ? 5000 : false,
  })

  useEffect(() => {
    if (bomImportStatus) {
      if (bomImportStatus.status === 'imported') {
        setIsCheckingBomImportStatus(false)
        setBomSpreadsheetUploadFile('')

        queryClient.invalidateQueries({ queryKey: ['bgs', 'specifications', 'bom'] })

        sessionStorage.removeItem('bom-upload')
        toast.success('Importação concluída com sucesso!')
      }

      if (bomImportStatus.status === 'failed') {
        setIsCheckingBomImportStatus(false)
        setBomSpreadsheetUploadFile('')

        toast.error(`Importação falhou: ${bomImportStatus.detail}`)
      }
    }
  }, [bomImportStatus])

  function handleTabChange(key: string) {
    setTabActiveKey(key as TabKeys)
  }

  async function handleCopyRowValue(key: string, value: string) {
    try {
      const resultString = `${bomProductLabelsRemap.get(key)}: ${value}`

      // Use the Clipboard API to copy the text to the clipboard
      await navigator.clipboard.writeText(resultString)

      antdToast.open({
        type: 'success',
        content: 'Copiado!',
        duration: 1,
      })
    } catch (error) {
      console.error('Failed to copy to clipboard:', error)

      antdToast.open({
        type: 'error',
        content: 'Erro ao copiar!',
        duration: 1,
      })
    }
  }

  async function handleCopyFormattedJSON(
    jsonData: { [key: string]: any } | Array<{ [key: string]: any }>,
  ) {
    try {
      // Normalize input to always be an array
      const normalizedData = Array.isArray(jsonData) ? jsonData : [jsonData]

      // Process each object in the array and format it
      const formattedEntries = normalizedData.map((data) => {
        return Object.entries(data)
          .reduce((acc, [key, value]) => {
            if (value !== null) {
              // Convert array values to a comma-separated string
              const formattedValue = Array.isArray(value) ? value.join(', ') : value
              acc.push(`${key}: ${formattedValue}`)
            }
            return acc
          }, [] as string[])
          .join(',\n')
      })

      // Join all formatted object entries, separated by "\n\n" for clarity between objects
      const resultString = formattedEntries.join('\n\n')

      await navigator.clipboard.writeText(resultString)

      antdToast.open({
        type: 'success',
        content: 'Copiado!',
        duration: 1,
      })
    } catch (error) {
      console.error('Failed to copy to clipboard:', error)

      antdToast.open({
        type: 'error',
        content: 'Erro ao copiar!',
        duration: 1,
      })
    }
  }

  const bomUploadProps: UploadProps = {
    action: '',
    accept: '.xlsx',
    maxCount: 1,
    multiple: false,
    showUploadList: false,
    disabled: isCheckingBomImportStatus || isUploadingFile,
    beforeUpload: (file) => {
      const isXlsx = file.name.endsWith('.xlsx')

      if (!isXlsx) {
        message.error('Você pode fazer upload apenas de arquivos .xlsx!')
      }

      return isXlsx || Upload.LIST_IGNORE
    },
    customRequest: async (options) => {
      const { onSuccess, onError, file } = options
      const toastId = toast.loading('Fazendo upload da planilha')
      setIsUploadingFile(true)
      try {
        const result = await fetchS3Url(file as File)

        const uploadResponse = await axios.put(result.url, file)

        if (uploadResponse.status !== 200) {
          throw new Error(`Falha ao fazer upload do arquivo. ${uploadResponse.statusText}`)
        }

        const s3Filename = result.filename

        await importBgBomSpecMutation.mutateAsync({ filename: s3Filename, toastId: toastId })

        setBomSpreadsheetUploadFile(s3Filename)
        setIsCheckingBomImportStatus(true)

        sessionStorage.setItem('bom-upload', s3Filename)
        setIsUploadingFile(false)

        onSuccess?.(s3Filename)
      } catch (error) {
        console.error(error)
        toast.dismiss(toastId)
        setIsUploadingFile(false)

        onError?.(error as ApiError)
      }
    },
  }

  function handleSelectBomProduct(record: BomProductResponse) {
    setBomProductDetails(record)
    toggleBomDetailsDrawer()
  }

  const SkeletonPage = () => {
    return (
      <S.SkeletonContainer>
        <S.SkeletonContent>
          <S.SkeletonUpload active size="large" />
        </S.SkeletonContent>

        <S.SkeletonContent>
          <S.SkeletonSearchInput active size="default" />
        </S.SkeletonContent>
        <S.SkeletonContent>
          <S.SkeletonInput active size="default" />
        </S.SkeletonContent>

        <S.SkeletonContent>
          <S.SkeletonInput active size="small" />
          <S.SkeletonInput active size="small" />
        </S.SkeletonContent>

        <S.SkeletonContent>
          <S.SkeletonInput active size="small" />
          <S.SkeletonInput active size="small" />
        </S.SkeletonContent>

        <S.SkeletonContent>
          <S.SkeletonInput active size="small" />
          <S.SkeletonInput active size="small" />
        </S.SkeletonContent>

        <S.SkeletonContent>
          <S.SkeletonInput active size="small" />
          <S.SkeletonInput active size="small" />
        </S.SkeletonContent>
      </S.SkeletonContainer>
    )
  }

  const productTabItems = [
    {
      key: 'bom',
      label: 'BOM ',
      content: (
        <S.FirstContainer>
          <S.ValuesContainer>
            <S.UploadContainer>
              <Upload.Dragger {...bomUploadProps}>
                {isCheckingBomImportStatus ? (
                  <LoadingSpinner />
                ) : (
                  <S.Column>
                    <UploadOutlined />
                    <strong>
                      Arraste ou clique no arquivo para adicionar a planilha ao sistema
                    </strong>
                    <span>Somente arquivos .XLS são compatíveis</span>
                  </S.Column>
                )}
              </Upload.Dragger>
            </S.UploadContainer>

            <BomSpecifications onSelectRow={handleSelectBomProduct} />
          </S.ValuesContainer>
        </S.FirstContainer>
      ),
    },
    {
      key: 'screens',
      label: 'Planilha de Telas',
      // content: (
      //   <S.FirstContainer>
      //     <S.ValuesContainer>
      //       {isBomLoading ? ( //! CHANGE TO 'PLANILHA DE TELAS' REQUISITION LOADING
      //         <SkeletonPage />
      //       ) : (
      //         <>
      //           {
      //             <S.UploadContainer>
      //               <Upload.Dragger {...screensUploadProps}>
      //                 <S.Column>
      //                   <UploadOutlined />
      //                   <strong>
      //                     Arraste ou clique no arquivo para adicionar a planilha ao sistema
      //                   </strong>
      //                   <span>Somente arquivos .XLS são compatíveis</span>
      //                 </S.Column>
      //               </Upload.Dragger>

      //               {isBomError && ( //! CHANGE TO 'PLANILHA DE TELAS' REQUISITION ERROR
      //                 <S.EmptyContainer>
      //                   <Empty
      //                     description={
      //                       <span>
      //                         Nenhum dado encontrado para o modelo:{' '}
      //                         <strong>{formValues?.model}</strong>
      //                       </span>
      //                     }
      //                   />
      //                 </S.EmptyContainer>
      //               )}
      //             </S.UploadContainer>
      //           }
      //           {bom && ( //! CHANGE TO 'PLANILHA DE TELAS' REQUISITION
      //             <S.LastUpdateDate>
      //               Atualizado em:<strong>{lastScreenDateSelected || '--/--/--'}</strong>
      //             </S.LastUpdateDate>
      //           )}
      //         </>
      //       )}
      //     </S.ValuesContainer>
      //   </S.FirstContainer>
      // ),
    },
    {
      key: 'fabricante',
      label: 'Fabricantes',
      disabledTransition: isCheckingBomImportStatus,
      content: (
        <S.SecondContainer>
          <S.DescriptionText>
            <span>
              Busque mais informações sobre o produto através do <strong>Fabricante</strong> e seu{' '}
              <strong>Identificador Único</strong>.
            </span>

            <ul>
              {selectedFabricante === 'intel' && (
                <li>
                  <strong>Intel:</strong>

                  <span>
                    Busque pelo <strong>NÚMERO</strong> de série.
                  </span>

                  <span>
                    Exemplo: <S.ExampleText>i5-7400</S.ExampleText>
                  </span>
                </li>
              )}

              {selectedFabricante === 'amd' && (
                <li>
                  <strong>AMD:</strong>

                  <span>
                    Busque pelo <strong>NOME</strong> específico.
                  </span>

                  <span>
                    Exemplo: <S.ExampleText>AMD Ryzen 7 PRO 8845HS</S.ExampleText>
                  </span>
                </li>
              )}

              {selectedFabricante === 'nvidia' && (
                <li>
                  <strong>Nvidia:</strong>
                  <span>
                    Busque pelo <strong>NOME</strong> da placa de vídeo.
                  </span>
                  <span>
                    Exemplo: <S.ExampleText>ASUS GTX 1650</S.ExampleText>
                  </span>
                </li>
              )}
            </ul>
          </S.DescriptionText>

          <Form layout="vertical" form={form}>
            <S.SearchContent>
              <Form.Item label="Fabricante" name={'fabricante'} className="select">
                <S.Select
                  options={[
                    { value: 'intel', label: 'Intel' },
                    { value: 'amd', label: 'AMD' },
                    { value: 'nvidia', label: 'Nvidia' },
                  ]}
                />
              </Form.Item>

              <Form.Item label="Identificador único" name={'search'} className="input">
                <S.SearchInput
                  placeholder={selectedFabricante ? 'Buscar...' : ''}
                  disabled={!selectedFabricante}
                  allowClear
                />
              </Form.Item>
            </S.SearchContent>
          </Form>

          <S.ValuesContainer>
            {!isScrapingInitialLoading && !isError && !debouncedScrapingSearch && (
              <S.EmptyContainer>
                <Empty description="Por favor, selecione um fabricante e um identificador único para buscar mais dados" />
              </S.EmptyContainer>
            )}

            {isError && (
              <S.EmptyContainer>
                <Empty
                  description={
                    <span>
                      Nenhum dado encontrado para o campo <strong>{debouncedScrapingSearch}</strong>
                    </span>
                  }
                />
              </S.EmptyContainer>
            )}

            {isScrapingInitialLoading || (fetchStatus === 'fetching' && !isFetched) ? (
              <SkeletonPage />
            ) : (
              scraping &&
              !!debouncedScrapingSearch && (
                <>
                  {/* Nvidia */}
                  {Array.isArray(scraping) ? (
                    <>
                      {(scraping as unknown as ScrapingSpecification[])?.map((item, index) => {
                        return (
                          <Fragment
                            key={`${item?.display_name || debouncedScrapingSearch} - ${index}`}
                          >
                            <S.ListValueTitle>
                              {item?.display_name || debouncedScrapingSearch}
                            </S.ListValueTitle>

                            {Object.entries(item || {})?.map(([key, value], index, array) => {
                              if (array.length === 0) {
                                return (
                                  <span key={`${index}-${crypto.randomUUID()}`}>
                                    Nenhum resultado encontrado
                                  </span>
                                )
                              }

                              const isLastItem = index === array.length - 1

                              return (
                                <>
                                  <S.Content key={`${key}-${index}`}>
                                    <S.TextKey>{key}:</S.TextKey>
                                    <S.TextDescription>{value ? value : '-'}</S.TextDescription>

                                    <Button
                                      type="link"
                                      className="copy-button"
                                      onClick={() => handleCopyRowValue(key, value)}
                                    >
                                      <CopyOutlined />
                                    </Button>
                                  </S.Content>

                                  {!isLastItem && <Divider style={{ margin: 0 }} />}
                                </>
                              )
                            })}
                          </Fragment>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      <S.SearchValueTitle>{debouncedScrapingSearch}</S.SearchValueTitle>

                      {Object.entries(scraping || {})?.map(([key, value], index, array) => {
                        if (array.length === 0) {
                          return (
                            <span key={`${index}-${crypto.randomUUID()}`}>
                              Nenhum resultado encontrado
                            </span>
                          )
                        }

                        const isLastItem = index === array.length - 1

                        return (
                          <>
                            <S.Content key={`${key}-${index}`}>
                              <S.TextKey>{key}:</S.TextKey>
                              <S.TextDescription>{value ? value : '-'}</S.TextDescription>

                              <Button
                                type="link"
                                className="copy-button"
                                onClick={() => handleCopyRowValue(key, value)}
                              >
                                <CopyOutlined />
                              </Button>
                            </S.Content>

                            {!isLastItem && <Divider style={{ margin: 0 }} />}
                          </>
                        )
                      })}
                    </>
                  )}
                </>
              )
            )}
          </S.ValuesContainer>
        </S.SecondContainer>
      ),
    },
  ]

  return (
    <S.Drawer
      title={
        <TitleTabs items={productTabItems} defaultActiveKey={'bom'} onChange={handleTabChange} />
      }
      placement="right"
      onClose={() => !isUploadingFile && onClose()}
      open={isOpen}
      closable={false}
      width={729}
      extra={<CloseOutlined onClick={() => !isUploadingFile && onClose()} />}
      footer={
        <S.CloseButton onClick={onClose} disabled={isUploadingFile}>
          Fechar
        </S.CloseButton>
      }
    >
      {antdToastContextHolder}

      {productTabItems.map((item) => {
        if (item.key === tabActiveKey) {
          return item.content
        }

        return null
      })}

      {isBomDetailsDrawerOpen && (
        <S.Drawer
          title={<S.BomDetailsHeader>Detalhes - {bomProductDetails?.model}</S.BomDetailsHeader>}
          open={isBomDetailsDrawerOpen}
          onClose={toggleBomDetailsDrawer}
          width={729}
          closable={false}
          extra={<CloseOutlined onClick={toggleBomDetailsDrawer} />}
          footer={
            <>
              <S.CloseButton onClick={toggleBomDetailsDrawer}>Fechar</S.CloseButton>

              <Button
                type="primary"
                icon={<CopyOutlined />}
                onClick={() => handleCopyFormattedJSON(bomProductDetails as BomProductResponse)}
              >
                Copiar Conteúdo
              </Button>
            </>
          }
        >
          {bomProductDetails &&
            Object.entries(bomProductDetails).map(([key, value], index, array) => {
              const isLastItem = index === array.length - 1
              const isValueArray = Array.isArray(value)

              let displayValue = value ? value : '-'

              if (typeof value !== 'string' && !isValueArray) {
                displayValue = '-'
              }

              if (isValueArray) {
                displayValue = value.join(', ')
              }

              return (
                <Fragment key={`${bomProductDetails?.model}}-${key}-${index}`}>
                  <S.Content>
                    <S.TextKey>{bomProductLabelsRemap.get(key)}:</S.TextKey>
                    <S.TextDescription>{truncateString(displayValue, 65)}</S.TextDescription>

                    <Button
                      type="link"
                      className="copy-button"
                      disabled={isCheckingBomImportStatus}
                      onClick={() => handleCopyRowValue(key, displayValue as string)}
                    >
                      <CopyOutlined />
                    </Button>
                  </S.Content>

                  {!isLastItem && <Divider style={{ margin: 0 }} />}
                </Fragment>
              )
            })}
        </S.Drawer>
      )}
    </S.Drawer>
  )
}
