import { Link, useLocation } from 'react-router-dom'

import { BellOutlined, CloseOutlined, MenuOutlined } from '@ant-design/icons'
import { useInfiniteQuery } from '@tanstack/react-query'
import { Badge, Image, Menu, MenuProps } from 'antd'

import * as S from './styles'

import { useRolesStore } from '@/app'
import buyersLogo from '@/assets/buyers-guide-logo.svg'
import { clearAllCaches, useAuth, useToggle } from '@/common'
import { AlertConfigurationModal } from '@/features/alerts'
import { notificationQueries, NotificationsDrawer } from '@/features/notification'
import { PlatformsHealthStatus } from '@/features/platforms'

type Route = {
  path: string
  label: string
  key: string
  allowedRoles?: string[]
}

const ROUTE_CONFIG: Route[] = [
  { path: '/dashboard', label: 'Dashboard', key: 'dashboard' },
  {
    path: '/agencies',
    label: 'Agências',
    key: 'agencies',
    allowedRoles: ['*', 'produto'],
  },
  {
    path: '/categories',
    label: 'Categorias',
    key: 'categories',
    allowedRoles: ['*'],
  },
  {
    path: '/specifications',
    label: 'Especificações',
    key: 'specifications',
    allowedRoles: ['*'],
  },
  {
    path: '/product-types',
    label: 'Tipo de Produto',
    key: 'product-types',
    allowedRoles: ['*'],
  },
]

type MenuItem = Required<MenuProps>['items'][number]

export const Header = () => {
  const location = useLocation()
  const { userAllowedModules } = useAuth()
  const isSystemAdmin = useRolesStore((state) => state.isSystemAdmin)
  const [isAlertConfigModalOpen, toggleAlertConfigModal] = useToggle(false)

  const {
    data: notifications,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending: isNotificationsLoading,
  } = useInfiniteQuery({
    ...notificationQueries.list(),
    refetchInterval: 60000 * 5, // 5 minutes,
    refetchIntervalInBackground: true,
  })

  const [isNavigationDrawerOpen, toggleNavigationDrawer] = useToggle(false)
  const [isNotificationsDrawerOpen, toggleNotificationsDrawer] = useToggle(false)

  const navigationMenuItems: MenuItem[] = ROUTE_CONFIG.map(({ path, label, allowedRoles }) => {
    if (isSystemAdmin) {
      return {
        key: path,
        label: <Link to={path}>{label}</Link>,
        path,
      }
    }

    // Remove link if user is not allowed
    if (
      allowedRoles &&
      !userAllowedModules?.includes('*') &&
      !userAllowedModules?.some((role) => allowedRoles.includes(role))
    ) {
      return null
    }

    return {
      key: path,
      label: <Link to={path}>{label}</Link>,
      path,
    }
  })

  if (isSystemAdmin) {
    navigationMenuItems.push({
      key: 'alert-config',
      label: <div onClick={openAlertConfigurationModal}>Configurar Alertas</div>,
    })
  }

  function openAlertConfigurationModal() {
    toggleNavigationDrawer()
    toggleAlertConfigModal()
  }

  return (
    <S.Container>
      {isAlertConfigModalOpen && (
        <AlertConfigurationModal isOpen={isAlertConfigModalOpen} onClose={toggleAlertConfigModal} />
      )}

      <S.LogoContainer>
        <Link to="/">
          <Image src={buyersLogo} alt={'Acer logo'} width={208} height={32} preview={false} />
        </Link>
      </S.LogoContainer>

      <S.MenuContainer>
        <PlatformsHealthStatus />

        <NotificationsDrawer
          isOpen={isNotificationsDrawerOpen}
          onClose={toggleNotificationsDrawer}
          data={notifications?.pages || []}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isInitialLoading={isNotificationsLoading}
          isLoadingMoreNotifications={isFetchingNextPage}
        />

        <S.HeaderActionsContainer>
          <S.HeaderButton type="text" onClick={toggleNotificationsDrawer}>
            <Badge
              size="small"
              count={notifications?.pages?.[0]?.total}
              overflowCount={20}
              style={{ fontFamily: 'AcerFoco' }}
            >
              <BellOutlined style={{ fontSize: 20 }} />
            </Badge>
          </S.HeaderButton>

          <S.MenuButton type="text" onClick={toggleNavigationDrawer}>
            Menu
            <MenuOutlined style={{ fontSize: 24 }} />
          </S.MenuButton>
        </S.HeaderActionsContainer>
      </S.MenuContainer>

      <S.MenuDrawer
        title="Menu"
        placement="right"
        onClose={toggleNavigationDrawer}
        open={isNavigationDrawerOpen}
        width={350}
        closable={false}
        className="drawer-menu"
        extra={<CloseOutlined onClick={toggleNavigationDrawer} />}
        footer={
          <S.LogOutButton type="default" onClick={() => clearAllCaches()}>
            Logout
          </S.LogOutButton>
        }
      >
        <S.MenuContent>
          <Menu mode="inline" items={navigationMenuItems} selectedKeys={[location.pathname]} />
        </S.MenuContent>
      </S.MenuDrawer>
    </S.Container>
  )
}
