import { useMutation } from '@tanstack/react-query'

import { StockAlertSettings } from '../../types'

import { api } from '@/services'

export const useCreateStockAlertMutation = () => {
  return useMutation({
    mutationFn: async (data: StockAlertSettings) => {
      return api.post('/settings', data)
    },
  })
}
