import { Tag } from 'antd'
import { rem } from 'polished'
import styled from 'styled-components'

interface BgTagsProps {
  $isBgView?: boolean
}

export const StyledTag = styled(Tag)<BgTagsProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${({ $isBgView }) => ($isBgView ? `${rem(27)}` : `${rem(21)}`)};
  width: fit-content;

  border-radius: 6px;
  font-size: ${({ $isBgView }) => ($isBgView ? `${rem(14)}` : `${rem(12)}`)};
  margin-top: ${({ $isBgView }) => ($isBgView ? null : 'auto')};
  font-weight: 600;
`
