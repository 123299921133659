import { useMutation } from '@tanstack/react-query'

import { StockAlertSettings } from '../../types'

import { api } from '@/services'

export const useUpdateStockAlertMutation = () => {
  return useMutation({
    mutationFn: async (data: StockAlertSettings) => {
      return api.put('/settings', data)
    },
  })
}
