import { Tooltip } from 'antd'

import { ProductStatus } from '../../types'
import { statusToComponentMap } from '../../utils'
import * as S from './styles'

import { statusTagRemap, tooltipStatusMap } from '@/common'

type BgStatusTagsProps = {
  status?: ProductStatus
  isBgView?: boolean
  showDescription?: boolean
}

const DefaultTag: React.ComponentType<any> = S.StyledTag

const BgStatusTags = ({ status, isBgView, showDescription }: BgStatusTagsProps) => {
  const TagComponent = statusToComponentMap[status as ProductStatus] || DefaultTag

  return (
    <>
      {showDescription ? (
        <Tooltip title={tooltipStatusMap.get(status as ProductStatus)}>
          <TagComponent $isBgView={isBgView}>
            {statusTagRemap.get(status as ProductStatus) || status}
          </TagComponent>
        </Tooltip>
      ) : (
        <TagComponent $isBgView={isBgView}>
          {statusTagRemap.get(status as ProductStatus) || status}
        </TagComponent>
      )}
    </>
  )
}

export { BgStatusTags }
