import { Modal as AntdModal } from 'antd'
import styled from 'styled-components'

export const Modal = styled(AntdModal)`
  display: flex;

  .ant-modal-content {
    padding: 0 !important;
    border-radius: 6px;
    border-bottom: 1px solid ${({ theme }) => theme.base.colorBorderSecondary} !important;
  }

  .ant-modal-body {
    padding: 16px 24px;
    overflow: hidden auto;
  }

  .alert-modal-body {
    padding: 0;
    padding-top: 16px;
  }

  .ant-modal-footer {
    padding: ${({ theme }) => theme.customTokens.margin.default}px
      ${({ theme }) => theme.customTokens.margin.LG}px;
    border-top: 1px solid ${({ theme }) => theme.base.colorBorderSecondary};
    display: flex;
    justify-content: space-between;
  }

  .ant-modal-close {
    padding: 4px;
    width: inherit;
    height: inherit;
    top: 16px;
  }

  .ant-modal-close-x {
    svg {
      fill: ${({ theme }) => theme.base.colorWhite};
    }
  }
`

export const Header = styled.header`
  background: ${(props) => props.theme.base.colorPrimary};
  color: ${(props) => props.theme.base.colorWhite};
  border-bottom: 1px solid ${(props) => props.theme.base.colorBorderSecondary};
  padding: ${(props) => props.theme.customTokens.margin.default}px
    ${(props) => props.theme.customTokens.margin.LG}px;
  display: flex;
  align-items: center;
  border-radius: 4px 4px 0 0;
  font-weight: 600;
`
