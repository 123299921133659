import { useEffect } from 'react'

import { Button, Form } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { ApiError, BaseModalProps, filtersRemap, toastError, useToggle } from '@/common'
import { BaseModal } from '@/components'
import {
  CategoryForm,
  editCategoryErrorMessages,
  useEditCategoryMutation,
  type Category,
  type CategoryFormData,
} from '@/features/categories'

interface ViewCategoryModalProps extends BaseModalProps {
  data: Category
}

export const ViewCategoryModal = ({ isOpen, onClose, data }: ViewCategoryModalProps) => {
  const [form] = Form.useForm<CategoryFormData>()

  const [isEditing, toggleEdit] = useToggle(false)
  const editCategoriesMutation = useEditCategoryMutation()

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        google_global_category_id: {
          label: data.global_category_name,
          value: data.global_category_id,
        },
        product_type_vtex_id: (filtersRemap.get(data?.product_type.name) ||
          data?.product_type.name ||
          data?.product_type.vtex_id) as any,
      })
    }
  }, [form, data])

  async function handleEditCategory(values: CategoryFormData) {
    const payload = {
      id: data.id,
      ...values,
      google_global_category_id: values.google_global_category_id.value,
    }

    const promise = editCategoriesMutation.mutateAsync(payload)

    toast.promise(promise, {
      loading: 'Editando categoria...',
      success: () => {
        onClose()

        return (
          <S.ToastContainer>
            <S.CircleOutlined />

            <div>
              Categoria <span style={{ fontWeight: 'bold' }}>editada</span> com{' '}
              <span style={{ fontWeight: 'bold' }}>sucesso</span>!
            </div>
          </S.ToastContainer>
        )
      },
      className: 'toast-sucess',
      error: (error: ApiError) =>
        toastError({
          error,
          errorMessages: editCategoryErrorMessages,
          defaultMessage: 'Erro ao editar categoria.',
        }),
    })
  }

  const CategoryView = () => (
    <>
      <S.FieldRow>
        <S.CategoryData>
          <strong>Nome</strong>

          <span>{data?.name}</span>
        </S.CategoryData>
      </S.FieldRow>

      <S.FieldRow>
        <S.CategoryData>
          <strong>Título da Página</strong>

          <span>{data?.title}</span>
        </S.CategoryData>

        <S.CategoryData>
          <strong>Palavras Similares</strong>

          <span>{data?.keywords}</span>
        </S.CategoryData>
      </S.FieldRow>

      <S.FieldRow>
        <S.CategoryData>
          <strong>Categoria Global</strong>

          <span>{data?.global_category_name}</span>
        </S.CategoryData>

        <S.CategoryData>
          <strong>Tipo de Produto</strong>

          <span>
            {(filtersRemap.get(data?.product_type.name) as string) || data?.product_type.name}
          </span>
        </S.CategoryData>

        <S.CategoryData>
          <strong>Categoria Anymarket</strong>

          <span>{data?.anymarket_name || '--'}</span>
        </S.CategoryData>
      </S.FieldRow>

      <S.FieldRow>
        <S.CategoryData>
          <strong>Descrição</strong>

          <span>{data?.description || '--'}</span>
        </S.CategoryData>
      </S.FieldRow>
    </>
  )

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Dados cadastrais da categoria"
      width={740}
      footer={
        <>
          <Button
            type="link"
            htmlType="button"
            disabled={editCategoriesMutation.isPending}
            onClick={isEditing ? toggleEdit : onClose}
          >
            Cancelar
          </Button>

          {!isEditing ? (
            <>
              <Button type="primary" htmlType="button" onClick={toggleEdit}>
                Editar
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
              loading={editCategoriesMutation.isPending}
            >
              Salvar
            </Button>
          )}
        </>
      }
    >
      <S.Container>
        {!isEditing ? (
          <CategoryView />
        ) : (
          <Form
            form={form}
            requiredMark
            layout="vertical"
            onFinish={handleEditCategory}
            disabled={editCategoriesMutation.isPending}
          >
            <CategoryForm isEditingCategory />
          </Form>
        )}
      </S.Container>
    </BaseModal>
  )
}
