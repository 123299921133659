import { queryOptions } from '@tanstack/react-query'

import { StockAlertResponse } from '@/features/alerts'
import { api } from '@/services'

export const alertQueries = {
  all: () => ['alerts'],
  stockSettings: () =>
    queryOptions({
      queryKey: [...alertQueries.all(), 'stock-settings'],
      queryFn: () => fetchStockAlertSetting(),
    }),
}

export const fetchStockAlertSetting = async () => {
  const { data } = await api.get<StockAlertResponse>('/settings')

  return data
}
