import { ProductStatus } from '../../types'
import * as S from './styles'

export const statusToComponentMap: Record<ProductStatus, React.ComponentType<any>> = {
  'EM RASCUNHO DO PRODUTO': S.SketchTag,
  'PENDENTE DE ENVIO PARA AGÊNCIA': S.MarketingTag,
  'PENDENTE DE LIBERAÇÃO DA AGÊNCIA': S.AgencyTag,
  'PENDENTE DE CADASTRO NO PROTHEUS': S.ProtheusTag,
  'EM RASCUNHO NO E-COMMERCE': S.SketchEcommerceTag,
  'PENDENTE DE PRECIFICAÇÃO': S.PrecificationTag,
  PUBLICADO: S.PublishedTag,
  DESPUBLICADO: S.UnpublishedTag,
  DESCONTINUADO: S.DiscontinuedTag,
}

export const bgStatus = Object.keys(statusToComponentMap)
