export const bomProductLabelsRemap = new Map([
  ['model', 'Model'],
  ['ref_id', 'Acer Part no.'],
  ['upc', 'UPC'],
  ['ean', 'EAN'],
  ['project_name', 'Project Name'],
  ['product_name', 'Product Name'],
  ['bom_name', 'BOM Name'],
  ['tender_type', 'Tender Type'],
  ['odm', 'ODM'],
  ['brand', 'Brand'],
  ['sub_brand', 'Sub Brand'],
  ['marketing_name', 'Marketing Name'],
  ['ro', 'RO'],
  ['ns', 'NS'],
  ['country', 'Country'],
  ['account_name', 'Account Name'],
  ['description', 'Description'],
  ['os_country_kit', 'OS-Country Kit'],
  ['cpu', 'CPU'],
  ['lcd', 'LCD'],
  ['odd_1', 'ODD 1'],
  ['vga_chip', 'VGA Chip'],
  ['vram_1', 'VRAM 1'],
  ['vram_2', 'VRAM 2'],
  ['vram_3', 'VRAM 3'],
  ['vram_4', 'VRAM 4'],
  ['memory_1', 'Memory 1'],
  ['memory_2', 'Memory 2'],
  ['memory_3', 'Memory 3'],
  ['memory_4', 'Memory 4'],
  ['hdd_1', 'HDD 1 (GB)'],
  ['hdd_2', 'HDD 2 (GB)'],
  ['hdd_3', 'HDD 3 (GB)'],
  ['hdd_4', 'HDD 4 (GB)'],
  ['card_reader', 'Card Reader'],
  ['wireless_lan', 'Wireless LAN'],
  ['battery', 'Battery'],
  ['battery_2', '2nd Battery'],
  ['extra_battery', 'Extra Battery'],
  ['adapter', 'Adapter'],
  ['adapter_2', 'Adapter 2'],
  ['camera', 'Camera'],
  ['camera_2', 'Camera 2'],
  ['camera_3', 'Camera 3'],
  ['wwan', 'WWAN'],
  ['a_cover', 'A_cover'],
  ['b_cover', 'B_cover'],
  ['finger_print', 'Finger Print'],
  ['special_package', 'Special Package'],
  ['ms_special', 'MS Special'],
  ['cobrand_royalty', 'Co-Brand Royalty'],
  ['extra_ap', 'Extra AP'],
  ['accessory_1', 'Accessory 1'],
  ['accessory_2', 'Accessory 2'],
  ['accessory_3', 'Accessory 3'],
  ['accessory_4', 'Accessory 4'],
  ['accessory_5', 'Accessory 5'],
  ['accessory_6', 'Accessory 6'],
  ['accessory_7', 'Accessory 7'],
  ['accessory_7', 'Accessory 8'],
  ['special_request', 'Special Request'],
  ['logo', 'Logo'],
  ['os', 'OS'],
  ['os_2', 'OS2'],
  ['country_kit', 'Country Kit'],
  ['softload', 'Softload'],
  ['os_manual', 'OS Manual'],
  ['kb', 'K/B'],
  ['users_guide', "User's Guide"],
  ['power_cord', 'Power Cord'],
  ['warranty_card', 'Warranty Card'],
  ['label', 'Label'],
  ['cd', 'CD'],
  ['printing', 'Printing'],
  ['unique_itens_remark', 'Unique Items/Remark'],
  ['ship_mark', 'Ship Mark'],
  ['pn_apply_time', 'P/N Apply Time'],
  ['pn_cfm_time', 'P/N CFM Time'],
  ['for_description', 'Remark for Description (Max 2 Digits)'],
  ['nb_chipset', 'NB Chipset'],
  ['sim_lock', 'SIM Lock'],
])
