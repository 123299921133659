import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'

import { bgQueries } from '../queries'

import { api } from '@/services'

interface Test {
  filename: string
  toastId?: string | number
}

export const useImportBomSpreadsheet = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ filename }: Test) => {
      return api.post(`/bom?s3_filename=${filename}`, filename)
    },
    onSuccess: (_, { toastId }) => {
      if (toastId) {
        toast.dismiss(toastId)
      }

      toast.info(
        'Planilha enviada com sucesso! O processamento será concluído em alguns minutos.',
        {
          className: 'toast-sucess',
        },
      )
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: bgQueries.all() }),
  })
}
