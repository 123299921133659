import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem(144)};

  .ant-form-item {
    width: 20%;
    &:nth-child(1) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 40%;
    }
    &:last-of-type {
      width: 40%;
    }
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.customTokens.input.label};

  > span {
    padding-top: 2px;
    font-size: 10px;
  }
`
